// src/components/LoadingScreen.js
import React from 'react';
import './LoadingScreen.css'; // Optional for styling
import { LoaderComponent } from './Common';

const LoadingScreen = () => {
  return (
    <div className="loading-overlay" id='loadingComponent'>
      {/* <div className="loading-spinner">Loading...</div> */}
        <LoaderComponent />
    </div>
  );
};

export default LoadingScreen;
