import { useContext, useEffect } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";

const NoAccessPage = () => {

    const { loading, setLoading } = useContext(StudioMH02Context);
    useEffect(() => {
        setLoading(false);
    }, []);

    return(
        <div style = {{backgroundColor:'#287475',color:'white',width:'50%',margin:'auto',padding:'5%',borderRadius:'2%',fontSize:'xxx-large'}}>
            Seems that you do not have access to the Page !!
        </div>
    );
};

export default NoAccessPage;