import { useContext, useEffect } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";


export default function RedirectPage() {

    const { loading, setLoading } = useContext(StudioMH02Context);

    useEffect(() => {
        setLoading(false);
    }, []);


    return (
        <div style={{backgroundColor:'#287475',color:'white',fontSize:'xx-large',width:'80%',margin:'auto'}}>
            <span>We will be available soon, in the meanwhile
                Please e-mail us at info@studiomh02.com</span>
        </div>
    )
};