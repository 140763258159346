import './Home.css';
import { useNavigate } from "react-router-dom";
import { BlogCard, fetchBlogs, fetchProjects, HomeSlider, ProjectCard, underlineNavBar } from '../common/Common';
import { useContext, useEffect, useState } from 'react';
import { BASE_API, FETCH_BLOG_API, FETCH_HOME_SLIDER_IMAGES, FETCH_IMAGES, FETCH_PROJECT_API, FETCH_STUDIO_HOME_TEXT, GET_HOME_TEXT_FOR_STUDIO, GET_IMAGES } from '../common/Constants';
import { StudioMH02Context } from '../common/StudioMH02Context';
import axios from 'axios';
import Carousel from './Carousel';

export default function Home() {

  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [sliderImagesData, setSliderImagesData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { loading, setLoading } = useContext(StudioMH02Context);
  const [studioText, setStudioText] = useState("");

  function handleClick() {
    navigate("/the-studio");
    underlineNavBar();
  };

  function learnMoreProjects() {
    navigate("/our-work/?filter=all-projects");
    underlineNavBar();
  }

  function learnMoreBlogs() {
    navigate("/blogs");
    underlineNavBar();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  useEffect(() => {    
    // Automatically cycle through the carousel items
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImagesData.length);
    }, 5000); // Adjust the interval to your preference

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [sliderImagesData]);

  const fetchData = async () => {
    setLoading(true);
    await fetchHomeSliderImages();
    await fetchStudioHomeText();
    await fetchProjectsAndBlogs();
    setLoading(false);
  };

  const fetchStudioHomeText = async () => {
    axios.post(BASE_API+GET_HOME_TEXT_FOR_STUDIO)
      .then(response => {
        const value = response.data.value;
        setStudioText(value);
      })
      .catch(error => {
        console.error('Error fetching homeText:', error);
      });
  }

  const fetchHomeSliderImages = async () => {
    try {
      const associatedType = "home-slider-images";
      const response = await axios.post(BASE_API+GET_IMAGES+`${associatedType}`);
      let imageNames = response.data;   
      setSliderImagesData(imageNames);
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchProjectsAndBlogs = async () => {
    try {
      fetchProjects(1,-1).then((data) => {
        setProjectData(data);
      });
      fetchBlogs(1,-1).then((data) => {
        setBlogData(data);
      });
    } catch (e) {
      setLoading(false);
    }

  };

  return (
    <div className="home-page-container">
      {/* <div>
        <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {sliderImagesData?.map((imageUrl, index) => <HomeSlider imageUrl={imageUrl} index={index} currentIndex={currentIndex} />)}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div> */}
      <div>
        <Carousel />
      </div>
      <div className='home-page-contents'>
        <hr className='hr-home' />
        <div className='row'>
          <span className='heading-home'>The Studio</span>
          <div className='studio-text'>
            <div dangerouslySetInnerHTML={{ __html: studioText }} />
          </div>
        </div>
        <div className='row'>
          <div className='row' style={{ marginBottom: '3%', marginTop: '3%' }}>
            <button className="learn-more-btn" onClick={() => { handleClick() }} style={{ marginBottom: '3% !important' }}>Read More </button>
          </div>
        </div>
        <hr className='hr-home' />
        <div className='row'>
          <div className='row' style={{ marginTop: '2%' }}>
            <span className='heading-home'>Our Work</span>
          </div>
          <div className='row' style={{ margin: 'auto', width: '80%' }}>
            {
              projectData.map((project, index) => <ProjectCard data={project} index={index} />)
            }
          </div>
          <div className='row' style={{ marginTop: '4%' }}>
            <button className="learn-more-btn" onClick={() => { learnMoreProjects() }}>Learn More </button>
          </div>
        </div>
        <hr className='hr-home' style={{ marginTop: '3%' }} />
        <div className='row'>
          <div className='row' style={{ marginTop: '3%' }}>
            <span className='heading-home'>Blogs</span>
          </div>
          <div className='row' style={{ margin: 'auto', width: '80%' }}>
            {
              blogData.map((blog, index) => <BlogCard data={blog} index={index} />)
            }
          </div>
          <div className='row' style={{ marginTop: '4%' }}>
            <button className="learn-more-btn" onClick={() => { learnMoreBlogs() }}>Read More </button>
          </div>
        </div>
      </div>
    </div>
  );
};