import { useContext, useEffect, useState } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { ADD_JOB_OPTION, BASE_API, DELETE_JOB_OPTION, DELETE_JOB_OPTION_API, FETCH_JOB_OPTION, FETCH_JOB_OPTIONS_API, UPLOAD_JOB_OPTION } from "../common/Constants";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

const ManageJobOptions = () => {

    const { loading, setLoading } = useContext(StudioMH02Context);
    const [jobOptionsData ,setJobOptionsData] = useState({jobTitle:"",jobDescription:""});
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        await fetchExistingJobOptions();
        setLoading(false);
    };

    const fetchExistingJobOptions = async () => {
        const jobOptions = [];
        axios.post(BASE_API + FETCH_JOB_OPTIONS_API)
            .then((response) => {                
                const jobs = response?.data;
                jobs.forEach(job => {
                    jobOptions.push(job.jobTitle);
                });
                setOptions(jobOptions);
            })
    };

    const addJobOptions = (e) => {
        e.preventDefault();
        const payload = new FormData();
        payload.append('jobTitle', jobOptionsData.jobTitle)
        payload.append('jobDescription', jobOptionsData.jobDescription)

        axios.post(BASE_API + ADD_JOB_OPTION, payload)
        .then((response) => {
            if (response?.data === "Job option added successfully") {
                setModalMessage("Job Option added Successfully!");
                setOptions([...options,jobOptionsData?.jobTitle]);
                setShowModal(true);
                setJobOptionsData({jobTitle:"",jobDescription:""});
            } else {
                setModalMessage("Error while adding job option. Please try again!");
                setShowModal(true);
            }
            setTimeout(() => {
                setShowModal(false);
                setModalMessage("");
            }, 3000)
        })
        .catch(() => {
            setModalMessage("Error while adding job option. Please try again!");
            setShowModal(true);
            setTimeout(() => {
                setShowModal(false);
                setModalMessage("");
            }, 3000)
        })

    }
    const handleClose = () => {
        setShowModal(false);
        setModalMessage("");
    }

    ManageJobOptions.modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    ManageJobOptions.formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];

    ManageJobOptions.propTypes = {
        placeholder: PropTypes.string,
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const removeJobOption =(e) => {
        const payload = new FormData();
        payload.append("jobTitle",selectedOption);
        axios.post(BASE_API + DELETE_JOB_OPTION_API, payload)
        .then(response => {
            if(response.data=== "Job option deleted successfully"){
                setOptions(options.filter(a => a!==selectedOption));
            }
        })
    }

    return(
        <div>
            <span className='heading-home'>Manage Job Options</span>
            <hr className="hr-home" />
            <form encType="multipart/form-data" onSubmit={addJobOptions} className='contact-us-form'>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div className='form-group' style={{ width: '50%', margin: 'auto' }}>
                        <input type="text" class="form-control" id="jobTitle" placeholder="Job Title *" required onChange={(e) => setJobOptionsData({ ...jobOptionsData, jobTitle: e.target.value })} />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="jobDescription" class="col-sm-2 col-form-label upload-blog-label">Job Description:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => setJobOptionsData({ ...jobOptionsData, jobDescription: e })}
                                value={jobOptionsData.jobDescription}
                                modules={ManageJobOptions.modules}
                                formats={ManageJobOptions.formats}
                                bounds=".app"
                                placeholder={"Job Description "}
                            />
                        </div>
                    </div>
                </div>
                <div className='row' style={{ marginTop: '4%' }}>
                    <button className="learn-more-btn" >Add </button>
                </div>
            </form>
            <hr className="hr-home" />
            {
                options.length !== 0 &&
                <div>
                    <label htmlFor="dynamic-select" style={{ marginRight: '2rem' }}>Choose a team member to remove:</label>
                    <select id="dynamic-select" value={selectedOption} onChange={handleSelectChange}>
                        <option value="">Select an option</option>
                        {options?.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <div className='row' style={{ marginTop: '4%' }}>
                        <button className="learn-more-btn" disabled={selectedOption === ""} onClick={(e) => removeJobOption(e)}>Remove </button>
                    </div>
                </div>
            }

            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                </Modal.Body>
            </Modal>
        </div>
    )

};
export default ManageJobOptions;