import { useContext, useEffect, useState } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";
import './TheStudio.css';
import { BASE_API, BASE_API_STATIC_COMMON_FOLDER, FETCH_IMAGES, FETCH_STUDIO_CONTENT, FETCH_TEAM_MEMBERS, FETCH_TEAM_MEMBERS_API, GET_IMAGES } from "../common/Constants";
import { ClientCard, TeamMemberCard, underlineNavBar } from "../common/Common";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function TheStudio() {
    const navigate = useNavigate();
    const { loading, setLoading } = useContext(StudioMH02Context);
    const [client, setClient] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [studioData, setStudioData] = useState({ homeText: "", studioMainImage: [], studioWhoWeAreImage: [], studioWhoWeAreImage2: [], whoWeAreText: "", missionAndValuesImage: [], missionAndValuesText: "", whatWeDoImage: [], whatWeDoText: "", howWeWorkImage: [], howWeWorkText: "", ourWorkPlaceImage: [], ourWorkPlaceText: "" });

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);

        await fetchStudioContent();
        await fetchClients();
        await fetchTeamMembers();

        setLoading(false);

    };

    const fetchStudioContent = async () => {
        const response = await axios.post(BASE_API + FETCH_STUDIO_CONTENT);
        const theStudio = response.data;
        const theStudioObj = {};
        theStudio?.theStudio.forEach(elem => {
            const values = (Object.values(elem));
            theStudioObj[values[0]] = values[1];
        })

        if (theStudio) {
            setStudioData({ homeText: theStudioObj?.["homeText"], whoWeAreText: theStudioObj?.["whoWeAreText"], missionAndValuesText: theStudioObj?.["missionAndValuesText"], whatWeDoText: theStudioObj?.["whatWeDoText"], howWeWorkText: theStudioObj?.["howWeWorkText"], ourWorkPlaceText: theStudioObj?.["ourWorkPlaceText"], studioMainImage: theStudio?.studioMainImage, studioWhoWeAreImage: theStudio?.studioWhoWeAreImage, studioWhoWeAreImage2: theStudio?.studioWhoWeAreImage2, missionAndValuesImage: theStudio?.missionAndValuesImage, whatWeDoImage: theStudio?.whatWeDoImage, howWeWorkImage: theStudio?.howWeWorkImage, ourWorkPlaceImage: theStudio?.ourWorkPlaceImage })
        }
    }

    const fetchClients = async () => {
        try {

            const associatedType = "client";
            const response = await axios.post(BASE_API + GET_IMAGES + `${associatedType}`);           
            setClient(response?.data?.code === "no_images" ? [] : response.data);
        } catch (e) {
        }
    }

    const fetchTeamMembers = async () => {
        try {
            const response = await axios.post(BASE_API + FETCH_TEAM_MEMBERS_API);
            
            setTeamData(response?.data?.message === "No team members found" ? [] : response?.data);
        } catch (e) {
        }
    }

    const joinTeamBtn = () => {
        navigate("/careers");
        underlineNavBar();
    }

    return (
        <div className='the-studio-container'>
            {!loading && studioData.homeText !== "" && studioData.homeText &&
                <div>
                    <div className="row the-studio-image-row">
                        <img class="studio-image" src={ BASE_API_STATIC_COMMON_FOLDER +'/the-studio/'+ studioData?.studioMainImage?.[0]} alt="Card image cap" />
                    </div>
                    <div className="row who-we-are-row">
                        <span className='who-we-are-heading' style={{ color: 'white !important' }}>Who We Are</span>
                        <div className="row" style={{ marginTop: '2%', marginBottom: '2%' }}>
                            <div className="col-md-6 who-we-are-image-row"><img class="studio-image" src={BASE_API_STATIC_COMMON_FOLDER +'/the-studio/' + studioData?.studioWhoWeAreImage?.[0]} alt="Card image cap" /></div>
                            <div className="col-md-6 who-we-are-text" ><div dangerouslySetInnerHTML={{ __html: studioData?.whoWeAreText }} /></div>
                        </div>
                    </div>
                    <div className="row the-studio-image-row">
                        <img class="studio-image" src={BASE_API_STATIC_COMMON_FOLDER +'/the-studio/' + studioData?.studioWhoWeAreImage2?.[0]} alt="Card image cap" />
                    </div>
                    <div class="accordion accordion-flush the-studio-accordian-card card" id="accordionFlushExample">
                        <div class="accordion-item the-studio-accordian">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <span className='accordion-title'>Mission And Values</span>
                                    <span class="accordion-icon">🡳</span>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 image-row-small ">
                                            {studioData?.missionAndValuesImage?.map(image => <img class="studio-image-small" src={BASE_API_STATIC_COMMON_FOLDER +'/the-studio/' + image} alt="Card image cap" />)}
                                        </div>
                                        <div className="col-md-6 accordion-text">
                                            <div dangerouslySetInnerHTML={{ __html: studioData?.missionAndValuesText }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item the-studio-accordian">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span className='accordion-title'>What We Do</span>
                                    <span class="accordion-icon">🡳</span>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 accordion-text">
                                            <div dangerouslySetInnerHTML={{ __html: studioData?.whatWeDoText }} />
                                        </div>
                                        <div className="col-md-6 image-row-small ">
                                            {studioData?.whatWeDoImage?.map(image => <img class="studio-image-small" src={BASE_API_STATIC_COMMON_FOLDER +'/the-studio/' + image} alt="Card image cap" />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item the-studio-accordian">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <span className='accordion-title'>How We Work</span>
                                    <span class="accordion-icon">🡳</span>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 image-row-small ">
                                            {studioData?.howWeWorkImage?.map(image => <img class="studio-image-small" src={BASE_API_STATIC_COMMON_FOLDER +'/the-studio/' + image} alt="Card image cap" />)}
                                        </div>
                                        <div className="col-md-6 accordion-text">
                                            <div dangerouslySetInnerHTML={{ __html: studioData?.howWeWorkText }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item the-studio-accordian">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    <span className='accordion-title'>Our Workplace</span>
                                    <span class="accordion-icon">🡳</span>
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div className="row">
                                        <div className="col-md-6 accordion-text">
                                            <div dangerouslySetInnerHTML={{ __html: studioData?.ourWorkPlaceText }} />
                                        </div>
                                        <div className="col-md-6 image-row-small ">
                                            {studioData?.ourWorkPlaceImage?.map(image => <img class="studio-image-small" src={BASE_API_STATIC_COMMON_FOLDER +'/the-studio/' + image} alt="Card image cap" />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item the-studio-accordian">
                            <h2 class="accordion-header" id="flush-headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    <span className='accordion-title'>Our Clients</span>
                                    <span class="accordion-icon">🡳</span>
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <div className='row' style={{ margin: 'auto', width: '80%' }}>
                                        {client?.map(data => <ClientCard data={data} />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row the-team-row">
                        <span className='who-we-are-heading' style={{ color: 'white !important', marginBottom: '2%' }}>The Team</span>
                        <div className="row team-data-row" style={{ marginTop: '2%', marginBottom: '2% !important', witdh: '80% !important' }}>
                            {
                                teamData?.map((member, index) => <TeamMemberCard data={member} index={index} />)
                            }
                        </div>
                        <span className='who-we-are-heading join-team-text' style={{ color: 'white !important', marginBottom: '2%', width: '80%', margin: 'auto', fontSize: 'xx-large !important' }}>Interested in joining our team? View the full list of openings on our careers page. </span>
                        <div className='row' style={{ marginBottom: '4%' }}>
                            <button className="learn-more-btn" onClick={() => { joinTeamBtn() }}>Join Team </button>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
};