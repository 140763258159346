import { useContext, useEffect, useState } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";
import { ADD_TEAM_MEMBER, ADD_TEAM_MEMBER_API, BASE_API, DELETE_TEAM_MEMBER, FETCH_TEAM_MEMBERS, FETCH_TEAM_MEMBERS_API, REMOVE_TEAM_MEMBER_API } from "../common/Constants";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

const ManageTeam = () => {
    const { loading, setLoading } = useContext(StudioMH02Context);
    const [teamData, setTeamData] = useState({ name: "", designation: "", image: [] })
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        await fetchExistingTeamMembers();
        setLoading(false);
    };

    const fetchExistingTeamMembers = async () => {
        const teamMembers = [];
        await axios.post(BASE_API + FETCH_TEAM_MEMBERS_API)
            .then((response) => {
                if (response?.data?.message !== "No team members found") {
                    const teamMemberArray = response?.data;
                    teamMemberArray.forEach(member => {
                        teamMembers.push(member.name);
                    });
                    setOptions(teamMembers);
                }
            })
    };

    const handleClose = () => {
        setShowModal(false);
        setModalMessage("");
    }

    const addTeamMember = (e) => {
        e.preventDefault();
        const payload = new FormData();
        payload.append('name', teamData.name);
        payload.append('designation', teamData.designation);
        payload.append("image", teamData.image[0])
        axios.post(BASE_API + ADD_TEAM_MEMBER_API, payload)
            .then((response) => {
                if (response?.data === "Team member added successfully") {
                    setModalMessage("Team member added Successfully!");
                    setOptions([...options, teamData?.name])
                    setShowModal(true);
                } else {
                    setModalMessage("Error while adding team member. Please try again!");
                    setShowModal(true);
                }
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while adding team member. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const removeTeamMember = (e) => {
        const payload = new FormData();
        payload.append('name', selectedOption);
        axios.post(BASE_API + REMOVE_TEAM_MEMBER_API, payload)
            .then(response => {

                if (response.data === "Team member deleted successfully") {
                    setModalMessage("Team member removed Successfully!");
                    fetchExistingTeamMembers();
                    setShowModal(true);
                } else {
                    setModalMessage("Error while removing team member. Please try again!");
                    setShowModal(true);
                }
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while removing team member. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
    }
    return (
        <div>
            <span className='heading-home'>Manage Team</span>
            <hr className="hr-home" />

            <form encType="multipart/form-data" onSubmit={addTeamMember} className='contact-us-form'>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div className='form-group' style={{ width: '50%', margin: 'auto' }}>
                        <input type="text" class="form-control" id="nameInput" placeholder="Team Member Name *" required onChange={(e) => setTeamData({ ...teamData, name: e.target.value })} />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div className='form-group' style={{ width: '50%', margin: 'auto' }}>
                        <input type="text" class="form-control" id="designationInput" placeholder="Team Member Designation*" required onChange={(e) => setTeamData({ ...teamData, designation: e.target.value })} />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label class="col-sm-7">Team Member Photo: </label>
                    <div class="col-sm-5" style={{ display: 'contents' }}>
                        <input onChange={(e) => setTeamData({ ...teamData, image: Array.from(e.target.files) })} type="file" class="form-control-file" id="teamMemberImage" />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '4%' }}>
                    <button className="learn-more-btn" >Add </button>
                </div>
            </form>
            <hr className="hr-home" />
            {
                options.length !== 0 &&
                <div>
                    <label htmlFor="dynamic-select" style={{ marginRight: '2rem' }}>Choose a team member to remove:</label>
                    <select id="dynamic-select" value={selectedOption} onChange={handleSelectChange}>
                        <option value="">Select an option</option>
                        {options?.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <div className='row' style={{ marginTop: '4%' }}>
                        <button className="learn-more-btn" disabled={selectedOption === ""} onClick={(e) => removeTeamMember(e)}>Remove </button>
                    </div>
                </div>
            }

            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                </Modal.Body>
            </Modal>
        </div>
    )


};
export default ManageTeam;