import react, { useContext, useEffect, useState } from 'react';
import { BlogCardRenderer, CardRenderer, fetchBlogs } from '../common/Common';
import { FETCH_BLOG_API, FETCH_PROJECT_API } from '../common/Constants';
import { StudioMH02Context } from '../common/StudioMH02Context';

export default function Blog() {
    const [data, setData] = useState([]);
    const { loading, setLoading } = useContext(StudioMH02Context);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchBlogsData();
    }, []);

    const fetchBlogsData = async () => {
        try {
            setLoading(true);
            fetchBlogs(null, -1).then((data) => {
                setData(data);
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    return (
        <div className='row' style={{ margin: 'auto', width: '80%' }}>
            {
                data.map((blog, index) => <BlogCardRenderer data={blog} index={index} totalBlogs={data.length} />)
            }
        </div>
    );
};