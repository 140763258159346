import { useContext, useEffect, useState } from 'react';
import './UploadBlog.css';
import { BASE_API, BLOG_UPLOADED_SUCCESSFULLY, PLEASE_SELECT_TAG_TEXT, PROJECT_UPLOADED_SUCCESSFULLY, UPLOAD_BLOG_API, UPLOAD_PROJECT_API, UPLOADING_BLOG, UPLOADING_PROJECT } from '../common/Constants';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { StudioMH02Context } from '../common/StudioMH02Context';
import mammoth from 'mammoth';


export default function UploadBlog() {
    const { loading, setLoading } = useContext(StudioMH02Context);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalBtn, setShowModalBtn] = useState(false);
    const [showOnHomePage, setShowOnHomePage] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [formData, setFormData] = useState({ title: "", description: "", showOnHomePage: false, image: [] });

    const [content, setContent] = useState('');
    useEffect(() => {
        setLoading(false);
    }, []);


    const uploadBlog = async (e) => {
        e.preventDefault();
        setModalMessage(UPLOADING_BLOG);
        setShowModal(true);
        const payload = new FormData();
        payload.append('title', formData.title)
        payload.append('description', content)
        payload.append('showOnHomePage', showOnHomePage?1:0)

        for (let i = 0; i < selectedFiles.length; i++) {
            payload.append('image', selectedFiles[i])
        }

        axios.post(BASE_API + UPLOAD_BLOG_API, payload)
            .then((response) => {
                setModalMessage(BLOG_UPLOADED_SUCCESSFULLY);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while Uploading contents. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
    };

    const handleClose = () => {
        setShowModalBtn(false);
        setShowModal(false);
    }

    const handleImageSelect = async(e) => {
        const files = Array.from(e.target.files)
        setSelectedFiles(files);
    }

    const handleFileSelected = async (event) => {


        const file = event.target.files[0];
        if (file) {
            const arrayBuffer = await file.arrayBuffer();
            const result = await mammoth.convertToHtml({ arrayBuffer });
            setContent(result.value);
        }
    }

    const onChangeData = (field, value) => {
        setFormData({ ...formData, [field]: value });
    }

    UploadBlog.modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    UploadBlog.formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];

    UploadBlog.propTypes = {
        placeholder: PropTypes.string,
    };

    return (
        <div className="upload-blog row" id="upload-blog-id" style={{ width: '75%', margin: 'auto' }}>
            <form encType="multipart/form-data" onSubmit={uploadBlog}>
                <div class="form-group row upload-blog-row">
                    <label for="blogTitleId" class="col-sm-2 col-form-label upload-blog-label">Blog Title</label>
                    <div class="col-sm-10"><input type="text" class="form-control" id="blogTitleId" required onChange={(e) => onChangeData('title', e.target.value)} aria-describedby="titleHelp" placeholder="Blog Title" />
                    </div>
                </div>
                <div class="form-group row upload-blog-row">
                    <label for="showOnHomePageId" class="col-sm-2 col-form-label upload-blog-label">Show Blog on Home Page</label>
                    <div class="col-sm-2" style={{ alignSelf: 'center' }}>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="showOnHomePageId" onChange={(e) => { setShowOnHomePage(e.target.checked) }} />
                        </div>
                    </div>
                </div>
                <div class="form-group row upload-blog-row">
                <div class="form-group row upload-blog-row">
                        <label for="uploadPhotosId" class="col-sm-2 col-form-label upload-blog-label">Upload Preview Image For Home Page</label>
                        <div class="col-sm-10" style={{ display: 'contents' }}>
                            <input onChange={handleImageSelect} type="file"  class="form-control-file" id="exampleFormControlFile1" />
                        </div>
                    </div>
                </div>
                <div class="form-group row upload-blog-row">
                    <div class="form-group row upload-blog-row">
                        <label for="upload-blog-doc" class="col-sm-2 col-form-label upload-blog-label">Upload Word Document For Blog</label>
                        <div class="col-sm-10" style={{ display: 'contents' }}>
                            <input onChange={handleFileSelected} type="file" accept=".docx" class="form-control-file" id="upload-blog-doc" multiple />
                        </div>
                    </div>

                    <span className='heading-home'>Preview: </span>
                    <div className='card' dangerouslySetInnerHTML={{ __html: content }} />

                    <div class="form-group row upload-blog-row">
                        <div class="col-sm-10">
                            <button className="learn-more-btn">Upload Blog </button>
                        </div>
                    </div>
                </div>
                
            </form>

            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                    {/* {modalMessage===MESSAGE_SENT_SUCCESSFULLY &&
                        <SuccessTick />
                    }
                    {modalMessage===SENDING_MESSAGE_TEXT &&
                        <LoaderComponent />
                    } */}
                </Modal.Body>
                {showModalBtn &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    );
}