import '../../components/webPages/Home.css'
import '../../App.css';
import './Common.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_API, BASE_API_STATIC_COMMON_FOLDER, FETCH_BLOG_API, FETCH_PROJECTS_API } from './Constants';
export const underlineNavBar = () => {
    
    document.querySelectorAll(".nav-link").forEach((link) => {        
        link.classList.remove("active");
        if(link.href.includes('/home-page') && window.location.href.includes('/home-page') && link.href.slice(-1) !=="#"){
            link.classList.add("active");
            link.setAttribute("aria-current", "page");
        } 
        else if(link.href.includes('/the-studio') && window.location.href.includes('/the-studio') && link.href.slice(-1) !=="#"){
            link.classList.add("active");
            link.setAttribute("aria-current", "page");
        } 
        else if(link.href.includes('/blogs') && window.location.href.includes('/blogs') && link.href.slice(-1) !=="#"){
            link.classList.add("active");
            link.setAttribute("aria-current", "page");
        } 
        else if(link.href.includes('/careers') && window.location.href.includes('/careers') && link.href.slice(-1) !=="#"){
            link.classList.add("active");
            link.setAttribute("aria-current", "page");
        } 
        else if(link.href.includes('/contact-us') && window.location.href.includes('/contact-us') && link.href.slice(-1) !=="#"){
            link.classList.add("active");
            link.setAttribute("aria-current", "page");
        }
        else if(link.href.includes('/our-work') && window.location.href.includes('/our-work')){                      
            link.classList.add("active");
            link.setAttribute("aria-current", "page");
        } 
    });
};


export const CardRenderer = ({ data, index, totalProjects }) => {

    if ((totalProjects - 1 === index) && ((totalProjects - 1) % 3 === 0)) {
        return (
            <>
                <div className='col-sm-4' />
                <div class="col-sm-4 project-blog-card-parent">
                    <a className="project-blog-card-link" href={"/project?projectId=" + data.id}>
                        <div class="card project-blog-card">
                            <img class="card-img-top our-work-img" src={BASE_API_STATIC_COMMON_FOLDER +'/project/'+ data?.images?.[0]} alt="Card image cap" />
                            <div class="card-body">
                                <h5 class="card-title">{data.title + " - " + data.subTitle}</h5>
                                <p class="card-text">{data.location}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </>
        );
    }
    else {
        return (
            <div class="col-sm-4 project-blog-card-parent">
                <a className="project-blog-card-link" href={"/project?projectId=" + data.id}>
                    <div class="card project-blog-card">
                        <img class="card-img-top our-work-img" src={BASE_API_STATIC_COMMON_FOLDER +'/project/'+ data?.images?.[0]} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">{data.title + " - " + data.subTitle}</h5>
                            <p class="card-text">{data.location}</p>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
};

export const BlogCardRenderer = ({ data, index, totalBlogs }) => {
    if ((totalBlogs - 1 === index) && ((totalBlogs - 1) % 3 === 0)) {
        return (
            <>
                <div className='col-sm-4' />
                <div class="col-sm-4 project-blog-card-parent">
                    <a className="project-blog-card-link" href={"/blog?blogId=" + data.id}>
                        <div class="card project-blog-card">
                            <img class="card-img-top our-work-img" src={BASE_API_STATIC_COMMON_FOLDER + '/blogs/' + data?.images} alt="Card image cap" />
                            <div class="card-body">
                                <h5 class="card-title">{data.title}</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </>
        );
    }
    else {
        return (
            <div class="col-sm-4 project-blog-card-parent">
                <a className="project-blog-card-link" href={"/blog?blogId=" + data?.id}>
                    <div class="card project-blog-card">
                        <img class="card-img-top our-work-img" src={BASE_API_STATIC_COMMON_FOLDER + '/blogs/'  + data?.image} alt="Card image cap" />
                        <div class="card-body">
                            <h5 class="card-title">{data.title}</h5>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
};

export const CareersCard = ({ data, index, totalOptions }) => {
    if ((totalOptions - 1 === index) && ((totalOptions - 1) % 3 === 0)) {
        return (
            <>
                <div className='col-sm-4' />
                <div class="col-sm-4 project-blog-card-parent">
                    <a className="project-blog-card-link" href={"/blog?blogId=" + data.id}>
                        <div class="card project-blog-card">
                            <div class="card-body">
                                <h5 class="card-title career-card-title">{data.jobTitle}</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </>
        );
    }
    else {
        return (
            <div class="col-sm-4 project-blog-card-parent">
                <a className="project-blog-card-link" href={"/blog?blogId=" + data._id.$oid}>
                    <div class="card project-blog-card">
                        <div class="card-body">
                            <h5 class="card-title">{data.title}</h5>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
};

export const LoaderComponent = () => {
    return (
        <div className='loading-three-dots'>
            <div class="snippet" data-title="dot-pulse">
                <div class="stage">
                    <div class="dot-pulse"></div>
                </div>
            </div>
        </div>
    );
};

export const SuccessTick = () => {
    return (
        <div id="success_tic" className='success-tick'>
            <h1 style={{ textAlign: "center" }}>
                <div class="checkmark-circle">
                    <div class="background"></div>
                    <div class="checkmark draw"></div>
                </div>
            </h1>
        </div>
    );
};

export const TagRenderer = (props) => {
    return (
        <div className='arrow-pointer-tag' >
            <p className='arrow-tag-name'>{props.tagName}</p>
        </div>
    )
}

export const ProjectCard = ({ data, index }) => {
    return (
        <div class="col-sm-4">
            <a className="project-blog-card-link" href={"/project?projectId=" + data.id}>
                <div class="card">
                    <img class="card-img-top our-work-img" src={BASE_API_STATIC_COMMON_FOLDER+'/project/' + data?.images?.[0]} alt="Card image cap" />
                    <div class="card-body">
                        <h5 class="card-title" style={{ fontWeight: 'bolder' }}>{data?.title}</h5>
                        <p class="card-text">{data?.subTitle}</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

export const BlogCard = ({ data, index }) => {
    return (
        <div class="col-sm-4">
            <a className="project-blog-card-link" href={"/blog?blogId=" + data.id}>
                <div class="card">
                    <img class="card-img-top our-work-img" src={BASE_API_STATIC_COMMON_FOLDER+ '/blogs/' + data?.image} alt="Card image cap" />
                    <div class="card-body">
                        <h5 class="card-title" style={{ fontWeight: 'bolder' }}>{data?.title}</h5>
                    </div>
                </div>
            </a>
        </div>
    )
}

export const TeamMemberCard = ({ data, index }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className="image-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img
                src={BASE_API_STATIC_COMMON_FOLDER + '/team/' + data?.image}
                alt="Hover"
                className={`image ${isHovered ? 'hovered' : ''}`}
                style={{ marginBottom: '3% !important' }}
            />
            {isHovered && (
                <div className="overlay-text">
                    <p style={{ fontSize: 'x-large' }}>{data?.name}</p>
                    <p>{data?.designation}</p>
                </div>
            )}
        </div>
    )
}

export const ClientCard = ({ data, index }) => {
    return (
        <div class="col-sm-2">
            <div class="card" style={{ boxShadow: '0 10px 20px -8px rgba(255, 255, 255, .7)' }}>
                <img class="card-img-top client-image" src={BASE_API_STATIC_COMMON_FOLDER+'/clients/' + data} alt="Card image cap" />
            </div>
        </div>
    )
}

export const HomeSlider = ({ imageUrl, index, currentIndex }) => {
    return (
        <div
            className={`carousel-item ${index === currentIndex ? 'active home-carousel' : 'home-carousel'}`}
            key={index}
        >
            <img class="d-block w-100  carousel-img-home" src={BASE_API_STATIC_COMMON_FOLDER+'/home/' + imageUrl} alt="Card image cap" />
        </div>
    )
}

export const JobOptionAccordion = ({ row, index }) => {
    const navigate = useNavigate();
    const applyNowBtn = (id) => {
        navigate("/contact-us?jobId=" + id);
        underlineNavBar();
    };    

    return (
        <div class="accordion-item the-studio-accordian" key={index}>
            <h2 class="accordion-header" id={`heading${index}`}>
                <button class={`accordion-button  collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={'false'} aria-controls={`collapse${index}`}>
                    <span class="circular-icon" >{getInitials(row.jobTitle)}</span>
                    <span className='accordion-title'>{row.jobTitle}</span>
                    <span class="accordion-icon">🡳</span>
                </button>
            </h2>
            <div id={`collapse${index}`} class={`accordion-collapse collapse `} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div className="row  job-option-accordion">
                        <div dangerouslySetInnerHTML={{ __html: row.jobDescription }} />
                    </div>
                    <div className='row'>
                        <button className="learn-more-btn" onClick={() => { applyNowBtn(row.id) }}>Apply Now </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function getInitials(str) {
    return str
        .split(' ')         // Split the string into an array of words
        .map(word => word[0]) // Get the first letter of each word
        .join('');          // Join them together into a single string
}

export const fetchProjects = async (showOnHomePage,projectId) => {
    try {
        const payload = new FormData();
        payload.append('showOnHomePage', showOnHomePage)
        payload.append('projectId', projectId)
        const response = await axios.post(BASE_API + FETCH_PROJECTS_API, payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching projects:', error);
        return [];
    }
};

export const fetchBlogs = async (showOnHomePage,blogId) => {
    try {
        const payload = new FormData();
        payload.append('showOnHomePage', showOnHomePage)
        payload.append('blogId', blogId)
        const response = await axios.post(BASE_API + FETCH_BLOG_API, payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching blogs:', error);
        return [];
    }
};
