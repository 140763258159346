export const BASE_API = "https://backend.studiomh02.com//wp-json/custom-api/v1";
export const BASE_API_STATIC_COMMON_FOLDER = "https://studiomh02.com/static/common";
export const LOGIN_API = "/login";
export const UPLOAD_IMAGE = '/upload-image';
export const GET_IMAGES = '/get-images/';
export const UPLOAD_STUDIO_DATA_API = '/upload-studio-data';
export const GET_HOME_TEXT_FOR_STUDIO = '/get-studio-value/homeText';
export const UPLOAD_PROJECT_API = '/add-project';
export const FETCH_PROJECTS_API = '/get_projects'; 
export const FETCH_STUDIO_CONTENT = '/fetch-studio-content';
export const UPLOAD_BLOG_API = '/upload-blogs';
export const FETCH_BLOG_API = '/fetch-blogs';
export const ADD_CLIENT_API = '/add-client';
export const REMOVE_CLIENT_API = '/delete-client';
export const ADD_TEAM_MEMBER_API = '/add-team-member';
export const FETCH_TEAM_MEMBERS_API = '/get-team-members';
export const REMOVE_TEAM_MEMBER_API = '/delete-team-member';
export const ADD_JOB_OPTION = '/add-job-option';
export const FETCH_JOB_OPTIONS_API ='/get-job-options';
export const DELETE_JOB_OPTION_API = '/delete-job-option';
export const SEND_MAIL_API = '/send-email';

export const PLEASE_SELECT_TEXT = "Please select atleast one option to specify 'What can we help you with?'";
export const PLEASE_SELECT_TAG_TEXT = "Please select atleast one option to specify Tags";
export const SENDING_MESSAGE_TEXT = "Sending Message";
export const MESSAGE_SENT_SUCCESSFULLY = "Message Sent Successfully!";
export const ERROR_WHILE_SENDING_MESSAGE = "Error while sending message. Please try again. If the error persists, please send an email to - info@studiomh02.in";
export const UPLOADING_PROJECT = "Uploading Project";
export const UPLOADING_BLOG = "Uploading Blog";
export const PROJECT_UPLOADED_SUCCESSFULLY = "Project Uploaded Successfully!";
export const BLOG_UPLOADED_SUCCESSFULLY = "Blog Uploaded Successfully!";
export const HOME_SLIDER_IMAGES_UPLOADED_SUCCESSFULLY = "Images for home slider uploaded Successfully!";

export const SUCCESS_RESPONSE = 'success';
export const FAILED_RESPONSE = 'failed';