import { useContext, useEffect, useState } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";
import { BASE_API, SAVE_STUDIO_HOME_TEXT, UPLOAD_HOME_SLIDER_IMAGES, UPLOAD_STUDIO_CONTENTS_API, UPLOAD_STUDIO_DATA_API } from "../common/Constants";
import axios from "axios";
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './../webPages/ContactUs.css';
import Modal from 'react-bootstrap/Modal';

const UploadStudioContents = () => {
    const { loading, setLoading } = useContext(StudioMH02Context);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [studioData, setStudioData] = useState({ homeText: "", studioMainImage: [], studioWhoWeAreImage: [], studioWhoWeAreImage2: [], whoWeAreText: "", missionAndValuesImage: [], missionAndValuesText: "", whatWeDoImage: [], whatWeDoText: "", howWeWorkImage: [], howWeWorkText: "", ourWorkPlaceImage: [], ourWorkPlaceText: "" });

    useEffect(() => {
        setLoading(false);
    }, []);

    const uploadStudioData = (e) => {


        e.preventDefault();
        const payload = new FormData();
        payload.append('homeText', studioData.homeText)
        payload.append('whoWeAreText', studioData.whoWeAreText)
        payload.append('missionAndValuesText', studioData.missionAndValuesText)
        payload.append('whatWeDoText', studioData.whatWeDoText)
        payload.append('howWeWorkText', studioData.missionAndValuesText)
        payload.append('ourWorkPlaceText', studioData.missionAndValuesText)

        for (let i = 0; i < studioData.studioMainImage.length; i++) {
            payload.append("studioMainImage[]", studioData.studioMainImage[i])
        }
        for (let i = 0; i < studioData.studioWhoWeAreImage.length; i++) {
            payload.append("studioWhoWeAreImage[]", studioData.studioWhoWeAreImage[i])
        }
        for (let i = 0; i < studioData.studioWhoWeAreImage2.length; i++) {
            payload.append("studioWhoWeAreImage2[]", studioData.studioWhoWeAreImage2[i])
        }
        for (let i = 0; i < studioData.missionAndValuesImage.length; i++) {
            payload.append("missionAndValuesImage[]", studioData.missionAndValuesImage[i])
        }
        for (let i = 0; i < studioData.whatWeDoImage.length; i++) {
            payload.append("whatWeDoImage[]", studioData.whatWeDoImage[i])
        }
        for (let i = 0; i < studioData.howWeWorkImage.length; i++) {
            payload.append("howWeWorkImage[]", studioData.howWeWorkImage[i])
        }
        for (let i = 0; i < studioData.ourWorkPlaceImage.length; i++) {
            payload.append("ourWorkPlaceImage[]", studioData.ourWorkPlaceImage[i])
        }


        axios.post(BASE_API + UPLOAD_STUDIO_DATA_API, payload)
            .then((response) => {
                if (response.data === "Data uploaded and saved successfully.") {
                    setModalMessage("Contents of Studio Page Uploaded Successfully!");
                    setShowModal(true);
                } else {
                    setModalMessage("Error while Uploading contents. Please try again!");
                    setShowModal(true);
                }
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while Uploading contents. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
    };

    const onChangeData = (field, value) => {
        setStudioData({ ...studioData, [field]: value });
    }

    UploadStudioContents.modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    UploadStudioContents.formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];

    UploadStudioContents.propTypes = {
        placeholder: PropTypes.string,
    };

    const handleClose = () => {
        setShowModal(false);
        setModalMessage("");
    }

    return (
        <div>
            <span className='heading-home'>Upload Studio Contents</span>
            <hr className="hr-home" />
            <form encType="multipart/form-data" onSubmit={uploadStudioData} className='contact-us-form'>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="studioHomeTextInput" class="col-sm-2 col-form-label upload-blog-label">Text For Home Page:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => onChangeData('homeText', e)}
                                value={studioData.homeText}
                                modules={UploadStudioContents.modules}
                                formats={UploadStudioContents.formats}
                                bounds=".app"
                                placeholder={"Enter Studio Home Text "}
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label class="col-sm-4">Studio Page Main Image:</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('studioMainImage', Array.from(e.target.files))} type="file" class="form-control-file" id="studioMainImage" />
                    </div>
                </div>

                <div class="form-group row upload-project-row">
                    <label class="col-sm-4"> Who We Are Image:</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('studioWhoWeAreImage', Array.from(e.target.files))} type="file" class="form-control-file" id="studioWhoWeAreImage" />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="whoWeAreTextInput" class="col-sm-2 col-form-label upload-blog-label">Who We Are Text:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => onChangeData('whoWeAreText', e)}
                                value={studioData.whoWeAreText}
                                modules={UploadStudioContents.modules}
                                formats={UploadStudioContents.formats}
                                bounds=".app"
                                placeholder={"Enter Who We Are Text "}
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row upload-project-row">
                    <label class="col-sm-4"> Who We Are Image (2):</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('studioWhoWeAreImage2', Array.from(e.target.files))} type="file" class="form-control-file" id="studioWhoWeAreImage2" />
                    </div>
                </div>

                <div class="form-group row upload-project-row">
                    <label class="col-sm-4"> Mission And Values Image:</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('missionAndValuesImage', Array.from(e.target.files))} type="file" class="form-control-file" id="missionAndValuesImage" multiple />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="missionAndValuesTextInput" class="col-sm-2 col-form-label upload-blog-label">Mission And Values Text:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => onChangeData('missionAndValuesText', e)}
                                value={studioData.missionAndValuesText}
                                modules={UploadStudioContents.modules}
                                formats={UploadStudioContents.formats}
                                bounds=".app"
                                placeholder={"Enter Mission And Values Text "}
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row upload-project-row">
                    <label class="col-sm-4"> What We Do Image:</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('whatWeDoImage', Array.from(e.target.files))} type="file" class="form-control-file" id="whatWeDoImage" multiple />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="whatWeDoTextInput" class="col-sm-2 col-form-label upload-blog-label">What We Do Text:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => onChangeData('whatWeDoText', e)}
                                value={studioData.whatWeDoText}
                                modules={UploadStudioContents.modules}
                                formats={UploadStudioContents.formats}
                                bounds=".app"
                                placeholder={"Enter What We Do Text "}
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row upload-project-row">
                    <label class="col-sm-4"> How We Work Image:</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('howWeWorkImage', Array.from(e.target.files))} type="file" class="form-control-file" id="howWeWorkImage" multiple />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="howWeWorkInput" class="col-sm-2 col-form-label upload-blog-label">How We Work Text:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => onChangeData('howWeWorkText', e)}
                                value={studioData.howWeWorkText}
                                modules={UploadStudioContents.modules}
                                formats={UploadStudioContents.formats}
                                bounds=".app"
                                placeholder={"Enter How We Work Text "}
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row upload-project-row">
                    <label class="col-sm-4"> Our Workplace Image:</label>
                    <div class="col-sm-6" style={{ display: 'contents' }}>
                        <input onChange={(e) => onChangeData('ourWorkPlaceImage', Array.from(e.target.files))} type="file" class="form-control-file" id="ourWorkPlaceImage" multiple />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div class="form-group row upload-blog-row">
                        <label for="ourWorkPlaceInput" class="col-sm-2 col-form-label upload-blog-label">Our Workplace Text:</label>
                        <div class="col-sm-10">
                            <ReactQuill
                                onChange={(e) => onChangeData('ourWorkPlaceText', e)}
                                value={studioData.ourWorkPlaceText}
                                modules={UploadStudioContents.modules}
                                formats={UploadStudioContents.formats}
                                bounds=".app"
                                placeholder={"Enter Our Workplace Text "}
                            />
                        </div>
                    </div>
                </div>
                <div className='row' style={{ marginTop: '4%' }}>
                    <button className="learn-more-btn" >Submit </button>
                </div>
            </form>
            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default UploadStudioContents;