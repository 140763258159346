import { useContext, useEffect, useState } from 'react';
import './UploadProject.css';
import { BASE_API, PLEASE_SELECT_TAG_TEXT, PROJECT_UPLOADED_SUCCESSFULLY, UPLOAD_PROJECT_API, UPLOADING_PROJECT } from '../common/Constants';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import { StudioMH02Context } from '../common/StudioMH02Context';

export default function UploadProject() {
    const { loading, setLoading } = useContext(StudioMH02Context);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalBtn, setShowModalBtn] = useState(false);
    const [showOnHomePage, setShowOnHomePage] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [formData, setFormData] = useState({ title: "", subTitle: "", description: "", client: "", area: "", tags: [], yearOfCompletion: "", location: "", showOnHomePage: false, images: [], projectStatus: "" });

    useEffect(()=> {
        setLoading(false);
    },[]);
    
    const uploadProject = async (e) => {
        if (selectedCheckboxes.length === 0) {
            setModalMessage(PLEASE_SELECT_TAG_TEXT);
            setShowModalBtn(true);
            setShowModal(true);
        }
        else {
            setModalMessage(UPLOADING_PROJECT);
            setShowModal(true);

            const payload = new FormData();
            payload.append('title', formData.title)
            payload.append('subTitle', formData.subTitle)
            payload.append('description', formData.description)
            payload.append('client', formData.client)
            payload.append('area', formData.area)
            payload.append('location', formData.location)
            payload.append('yearOfCompletion', formData.yearOfCompletion)
            payload.append('showOnHomePage', showOnHomePage?1:0)
            payload.append('tags', selectedCheckboxes)
            payload.append('projectStatus', formData.projectStatus)

            for (let i = 0; i < selectedFiles.length; i++) {
                payload.append("files[]", selectedFiles[i])
            }

            axios.post(BASE_API + UPLOAD_PROJECT_API, payload)
            .then((response) => {
                setModalMessage(PROJECT_UPLOADED_SUCCESSFULLY);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while Uploading contents. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })

        }

    };

    const onClickCheckBox = (value, field) => {
        if (value) {
            setSelectedCheckboxes([...selectedCheckboxes, field]);
        }
        else {
            setSelectedCheckboxes(selectedCheckboxes.filter(a => a !== field))
        }
    };

    const handleClose = () => {
        setShowModalBtn(false);
        setShowModal(false);
    }

    const handleFileSelected = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(files);
    }

    const onChangeData = (field, value) => {
        setFormData({ ...formData, [field]: value });
    }

    return (
        <div className="upload-project row" id="upload-project-id" style={{ width: '75%', margin: 'auto' }}>
            <form encType="multipart/form-data" onSubmit={uploadProject}>
                <div class="form-group row upload-project-row">
                    <label for="projectTitleId" class="col-sm-2 col-form-label upload-project-label">Project Title</label>
                    <div class="col-sm-10"><input type="text" class="form-control" id="projectTitleId" required onChange={(e) => onChangeData('title', e.target.value)} aria-describedby="titleHelp" placeholder="Project Title" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="projectSubTitleId" class="col-sm-2 col-form-label upload-project-label">Project Subtitle</label>
                    <div class="col-sm-10"><input type="text" class="form-control" id="projectSubTitleId" required onChange={(e) => onChangeData('subTitle', e.target.value)} aria-describedby="titleHelp" placeholder="Project Title" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="projectDescriptionId" class="col-sm-2 col-form-label upload-project-label">Project Description</label>
                    <div class="col-sm-10"><textarea type="text" class="form-control" required onChange={(e) => onChangeData('description', e.target.value)} id="projectDescriptionId" aria-describedby="descriptionHelp" placeholder="Project Description" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="projectStatusId" class="col-sm-2 col-form-label upload-project-label">Project Status</label>
                    <div class="col-sm-10"><input type="text" class="form-control" id="projectStatusId" required onChange={(e) => onChangeData('projectStatus', e.target.value)} aria-describedby="projectStatusHelp" placeholder="Project Status" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="clientId" class="col-sm-2 col-form-label upload-project-label">Client</label>
                    <div class="col-sm-10"><input type="text" class="form-control" id="clientId" required onChange={(e) => onChangeData('client', e.target.value)} aria-describedby="clientHelp" placeholder="Client" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="areaId" class="col-sm-2 col-form-label upload-project-label">Area</label>
                    <div class="col-sm-10"> <input type="text" class="form-control" id="areaId" required onChange={(e) => onChangeData('area', e.target.value)} aria-describedby="AreaHelp" placeholder="Area" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="yearID" class="col-sm-2 col-form-label upload-project-label">Year of Completion</label>
                    <div class="col-sm-10"><input type="text" class="form-control" id="yearId" required onChange={(e) => onChangeData('yearOfCompletion', e.target.value)} aria-describedby="YearHelp" placeholder="Year of Completion" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="locationId" class="col-sm-2 col-form-label upload-project-label">Location</label>
                    <div class="col-sm-10"> <input type="text" class="form-control" id="locationId" required onChange={(e) => onChangeData('location', e.target.value)} aria-describedby="LocationHelp" placeholder="Location" />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="locationId" class="col-sm-2 col-form-label upload-project-label">Show Project on Home Page</label>
                    <div class="col-sm-2" style={{ alignSelf: 'center' }}>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="showOnHomePageId" onChange={(e) => setShowOnHomePage(e.target.checked)} />
                        </div>
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label for="locationId" class="col-sm-2 col-form-label upload-project-label">Select Tags</label>
                    <div class="col-sm-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="architechureCheckbox" onChange={(e) => onClickCheckBox(e.target.checked, "Architecture")} />
                            <label class="form-check-label row select-type-label" for="architechureCheckbox">
                                Architecture
                            </label>
                            <input class="form-check-input" type="checkbox" id="interiorDesignCheckbox" onChange={(e) => onClickCheckBox(e.target.checked, "Interior Design")} />
                            <label class="form-check-label row select-type-label" for="interiorDesignCheckbox">
                                Interior Design
                            </label>
                            <input class="form-check-input" type="checkbox" id="outsourcingCheckbox" onChange={(e) => onClickCheckBox(e.target.checked, "Outsourcing")} />
                            <label class="form-check-label row select-type-label" for="outsourcingCheckbox">
                                Outsourcing
                            </label>
                        </div>
                    </div>
                    <div class="form-group row upload-project-row">
                        <label for="uploadPhotosId" class="col-sm-2 col-form-label upload-project-label">Upload Photos</label>
                        <div class="col-sm-10" style={{ display: 'contents' }}>
                            <input onChange={handleFileSelected} type="file" class="form-control-file" id="exampleFormControlFile1" multiple />
                        </div>
                    </div>
                    <div class="form-group row upload-project-row">
                        <div class="col-sm-10">
                            <button className="learn-more-btn">Upload Project </button>
                        </div>
                    </div>
                </div>
            </form>

            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                    {/* {modalMessage===MESSAGE_SENT_SUCCESSFULLY &&
                        <SuccessTick />
                    }
                    {modalMessage===SENDING_MESSAGE_TEXT &&
                        <LoaderComponent />
                    } */}
                </Modal.Body>
                {showModalBtn &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    );
}