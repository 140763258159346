import React, { useRef, useEffect, useState, useContext } from 'react';
import Slider from 'react-slick';
import './ProjectPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchProjects, TagRenderer } from '../common/Common';
import { BASE_API_STATIC_COMMON_FOLDER, FETCH_PROJECT_API } from '../common/Constants';
import { StudioMH02Context } from '../common/StudioMH02Context';

const ProjectPage = () => {
  const mainSlider = useRef(null);
  const thumbSlider = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const { loading, setLoading } = useContext(StudioMH02Context);
  const [data, setData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    if (thumbSlider.current) {
      thumbSlider.current.slickGoTo(currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    const imageList = [];
    
    data?.images?.forEach(image => {
      imageList.push( BASE_API_STATIC_COMMON_FOLDER+'/project/'+ image);
    });
    setImages(imageList);
    setLoading(false);
  }, [data])

  const fetchProjectDetails = async () => {
    try {
      setLoading(true);
      const projectId = queryParams.get('projectId');
      if (projectId) {
        fetchProjects(null,projectId).then((data) => {
          setData(data?.[0]);
        });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const settingsMain = {
    asNavFor: thumbSlider.current,
    ref: mainSlider,
    arrows: false,
    fade: true,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
    afterChange: (index) => thumbSlider.current.slickGoTo(index),
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
  };

  const settingsThumbs = {
    asNavFor: mainSlider.current,
    ref: thumbSlider,
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleThumbnailClick = (index) => {
    mainSlider.current.slickGoTo(index);
  };

  return (
    <div className="ProjectPage" style={{ marginTop: '-2.5%' }}>
      <div className="carousel-container">
        <Slider {...settingsMain} className="main-slider">
          {images.map((img, index) => (
            <div key={index}>
              <img src={img} alt={`Main ${index}`} />
            </div>
          ))}
        </Slider>
        <Slider {...settingsThumbs} className="thumb-slider">
          {images.map((img, index) => (
            <div key={index} onClick={() => handleThumbnailClick(index)}>
              <img src={img} alt={`Thumb ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className='project-details' style={{ marginTop: '1%' }}>
        <p className='project-title'>{data?.title + " - " + data?.subTitle}</p>
        <div className='row' style={{ justifyContent: 'center' }}>
          {
            data?.tags?.split(",").map(tag => (
              <TagRenderer tagName={tag} />
            ))
          }
        </div>
        <div className='row project-desc-row'>
          <div className='col-md-8'>
            <div className='row project-desc'>
              {data?.description}
            </div>
          </div>
          <div className='col-md-4'>
            <div class="card" style={{ borderWidth: 'medium', paddingTop: '5%', paddingBottom: '5%', fontSize: 'larger' }}>
              <div className='details-row'>
                <p className='detail-card-header'>Client</p>
                <p>{data?.client}</p>
              </div>
              <div className='details-row'>
                <p className='detail-card-header'>Location</p>
                <p>{data?.location}</p>
              </div>
              <div className='details-row'>
                <p className='detail-card-header'>Area</p>
                <p>{data?.area + " sq. ft."}</p>
              </div>
              <div className='details-row'>
                <p className='detail-card-header'>Year of completion</p>
                <p>{data?.yearOfCompletion}</p>
              </div>
              <div className='details-row'>
                <p className='detail-card-header'>Project Status</p>
                <p>{data?.projectStatus}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
