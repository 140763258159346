import { useContext, useEffect, useState } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";
import { ADD_CLIENT_API, BASE_API, DELETE_CLIENT, FETCH_CLIENT, GET_IMAGES, REMOVE_CLIENT_API, UPLOAD_HOME_SLIDER_IMAGES, UPLOAD_IMAGES } from "../common/Constants";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

const ManageClients = () => {
    const { loading, setLoading } = useContext(StudioMH02Context);
    const [client, setClient] = useState({ name: "", logo: [] });
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        await fetchExistingClients();
        setLoading(false);
    };

    const fetchExistingClients = async () => {
        const clients = [];

        const associatedType = "client";
        const response = await axios.post(BASE_API + GET_IMAGES + `${associatedType}`);
        const clientArray = typeof response?.data === Array ? response?.data : [];
        clientArray?.forEach(client => {
            clients.push(client?.split(".")?.[0]);
        });
        setOptions(clients);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const addClient = (e) => {
        e.preventDefault();
        const payload = new FormData();
        
        payload.append('clientName', client.name);
        payload.append("image", client.logo[0] );
        payload.append('associatedType', 'client')
        axios.post(BASE_API + ADD_CLIENT_API, payload)
            .then((response) => {
                if (response?.data?.message === "Client and image uploaded successfully") {
                    setModalMessage("Client added Successfully!");
                    setOptions([...options, client?.name])
                    setShowModal(true);
                } else {
                    setModalMessage("Error while adding client. Please try again!");
                    setShowModal(true);
                }
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while adding client. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
    };

    const handleClose = () => {
        setShowModal(false);
        setModalMessage("");
    }

    const removeClient = (e) => {
        const payload = new FormData();
        
        payload.append('clientName', selectedOption + '.png');
        axios.post(BASE_API + REMOVE_CLIENT_API, payload)
            .then(response => {

                if (response.data === "Image deleted successfully") {
                    setModalMessage("Client removed Successfully!");
                    fetchExistingClients();
                    setShowModal(true);
                } else {
                    setModalMessage("Error while removing client. Please try again!");
                    setShowModal(true);
                }
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
            .catch(() => {
                setModalMessage("Error while removing client. Please try again!");
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)
            })
    }

    return (
        <div>
            <span className='heading-home'>Manage Clients</span>
            <hr className="hr-home" />

            <form encType="multipart/form-data" onSubmit={addClient} className='contact-us-form'>
                <div className='row' style={{ marginTop: '3%' }}>
                    <div className='form-group' style={{ width: '50%', margin: 'auto' }}>
                        <input type="text" class="form-control" id="nameInput" placeholder="Client Name *" required onChange={(e) => setClient({ ...client, name: e.target.value })} />
                    </div>
                </div>
                <div class="form-group row upload-project-row">
                    <label class="col-sm-7">Client Logo Image: </label>
                    <div class="col-sm-5" style={{ display: 'contents' }}>
                        <input onChange={(e) => setClient({ ...client, logo: Array.from(e.target.files) })} type="file" class="form-control-file" id="clientLogo" />
                    </div>
                </div>
                <div className='row' style={{ marginTop: '4%' }}>
                    <button className="learn-more-btn" >Add </button>
                </div>
            </form>
            <hr className="hr-home" />
            {
                options.length !== 0 &&
                <div>
                    <label htmlFor="dynamic-select" style={{ marginRight: '2rem' }}>Choose a client to remove:</label>
                    <select id="dynamic-select" value={selectedOption} onChange={handleSelectChange}>
                        <option value="">Select an option</option>
                        {options?.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <div className='row' style={{ marginTop: '4%' }}>
                        <button className="learn-more-btn" disabled={selectedOption === ""} onClick={(e) => removeClient(e)}>Remove </button>
                    </div>
                </div>
            }




            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                </Modal.Body>
            </Modal>

        </div>
    )

};
export default ManageClients;