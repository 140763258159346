import { useState, useEffect, useContext } from 'react';
import './ContactUs.css';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { LoaderComponent, SuccessTick, underlineNavBar } from '../common/Common';
import { BASE_API, ERROR_WHILE_SENDING_MESSAGE, FETCH_JOB_OPTION, FETCH_JOB_OPTIONS_API, MESSAGE_SENT_SUCCESSFULLY, PLEASE_SELECT_TEXT, SEND_MAIL_API, SENDING_MESSAGE_TEXT, SUCCESS_RESPONSE } from '../common/Constants';
import { StudioMH02Context } from '../common/StudioMH02Context';

export default function ContactUs() {
    const queryParams = new URLSearchParams(window.location.search);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [formData, setFormData] = useState({ name: "", contactNumber: "", email: "", message: "", checkboxes: [], jobProfile: "" });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalBtn, setShowModalBtn] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const { loading, setLoading } = useContext(StudioMH02Context);
    const [jobOptions,setJobOptions] = useState(["Other"]);
    const jobOptionId = queryParams.get('jobId');

    useEffect(() => {
        window.scrollTo(0, 0);
        underlineNavBar();
        fetchJobOptions();
    }, []);

    const fetchJobOptions = async () => {
        setLoading(true);
        axios.post(BASE_API + FETCH_JOB_OPTIONS_API)
            .then((response) => {                
                const jobs = response?.data;
                const jobsList = jobs.map(a =>  a.jobTitle);
                const finalList = jobsList.concat('Other');
                setJobOptions(finalList);
            })        
        if(jobOptionId){
            document.getElementById("careerCheckBox")?.click();            
        }
        setLoading(false);
    }

    useEffect(() => {
        setFormData({ ...formData, checkboxes: selectedCheckboxes });
    }, [selectedCheckboxes]);

    const onClickCheckBox = (value, field) => {
        if (value) {
            setSelectedCheckboxes([...selectedCheckboxes, field]);
        }
        else {
            if (field === 'career') setFormData({ ...formData, jobProfile: "" })
            setSelectedCheckboxes(selectedCheckboxes.filter(a => a !== field))
        }
    };

    const onChangeData = (field, value) => {
        setFormData({ ...formData, [field]: value });
    }

    const handleFileSelected = (e) => {
        const files = Array.from(e.target.files)
        setSelectedFiles(files);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (selectedCheckboxes.length === 0) {
            setModalMessage(PLEASE_SELECT_TEXT);
            setShowModalBtn(true);
            setShowModal(true);
        }
        else {
            setModalMessage(SENDING_MESSAGE_TEXT);
            setShowModal(true);
            const payload = new FormData();
            payload.append('name', formData.name)
            payload.append('contactNumber', formData.contactNumber)
            payload.append('email', formData.email)
            payload.append('message', formData.message)
            payload.append('checkboxes', formData.checkboxes)
            payload.append('jobProfile', (formData.checkboxes.includes('career') && formData.jobProfile === "") ? 'Other' : formData.jobProfile);
            for (let i = 0; i < selectedFiles.length; i++) {
                payload.append("files[]", selectedFiles[i])
            }
            try {

                axios.post(BASE_API + SEND_MAIL_API, payload)
                    .then((response) => {
                        if (response.data === "Mail sent successfully with attachments.") {
                            setModalMessage(MESSAGE_SENT_SUCCESSFULLY);
                        }
                        else {
                            setModalMessage(ERROR_WHILE_SENDING_MESSAGE);
                        }
                        setTimeout(() => {
                            setShowModal(false);
                            setModalMessage("");
                        }, 3000)
                    })
            }
            catch (e) {
                setModalMessage(ERROR_WHILE_SENDING_MESSAGE);
            }
        }
    };

    const handleClose = () => {
        if (showModalBtn) {
            const checkBoxRow = document.getElementById("checkboxId");
            checkBoxRow.scrollIntoView();
            checkBoxRow.style.backgroundColor = 'rgba(224, 115, 44,0.5)';
            setShowModalBtn(false);
        }
        setShowModal(false);
    }

    return (
        <div>
            <div className='contact-us-div'>
                <span className='heading-home' style={{ color: 'white' }}>Contact Us</span>
                <hr className='hr-contact-us' />
                <form encType="multipart/form-data" className='contact-us-form' onSubmit={handleSubmit}>
                    <div className='row' style={{ marginTop: '3%' }}>
                        <div className='form-group col-md-6'>
                            <input type="text" class="form-control" id="nameInput" placeholder="Name *" required onChange={(e) => onChangeData('name', e.target.value)} />
                        </div>
                        <div className='form-group col-md-6 contact-number-input'>
                            <input type="number" class="form-control" id="contactNumberInput" placeholder="Contact Number *" required onChange={(e) => onChangeData('contactNumber', e.target.value)} />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '3%' }}>
                        <div class="form-group">
                            <input type="email" class="form-control" id="exampleFormControlInput" placeholder="Email address *" required onChange={(e) => onChangeData('email', e.target.value)} />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '3%' }}>
                        <div class="form-group">
                            <textarea class="form-control" id="messageInput" rows="3" placeholder="Message *" required onChange={(e) => onChangeData('message', e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '3%' }} id='checkboxId'>
                        <label className='contact-us-label'>
                            What can we help you with? *
                        </label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="businessCheckBox" onChange={(e) => onClickCheckBox(e.target.checked, "business")} />
                            <label class="form-check-label" htmlFor="businessCheckBox">
                                Business
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="marketingCheckBox" onChange={(e) => onClickCheckBox(e.target.checked, "marketing")} />
                            <label class="form-check-label" htmlFor="marketingCheckBox">
                                Marketing
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="careerCheckBox" onChange={(e) => onClickCheckBox(e.target.checked, "career")} />
                            <label class="form-check-label" htmlFor="careerCheckBox">
                                Career
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="otherCheckBox" onChange={(e) => onClickCheckBox(e.target.checked, "other")} />
                            <label class="form-check-label" htmlFor="otherCheckBox">
                                Other
                            </label>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '3%' }}>
                        <label className='contact-us-label'>
                            Select Files to Attach
                        </label>
                        <div class="form-group" style={{ display: 'flex' }}>
                            <input onChange={handleFileSelected} type="file" class="form-control-file" id="chooseFile" name='files' multiple />
                        </div>
                    </div>
                    {selectedCheckboxes.includes('career') &&
                        <div className='row' style={{ marginTop: '3%' }}>
                            <div class="form-group">
                                <label htmlFor="selectJobProfile" style={{ display: 'flex', fontSize: 'larger' }}>Select Job Profile *</label>
                                <select class="form-control" id="selectJobProfile" value={formData.jobProfile} style={{ appearance: 'menulist' }} onChange={(e) => onChangeData('jobProfile', e.target.value)}>
                                    {jobOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    }
                    <div className='row' style={{ marginTop: '4%' }}>
                        <button className="learn-more-btn" >Submit </button>
                    </div>
                </form>
            </div>

            <Modal show={showModal} onHide={handleClose} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                    {modalMessage === MESSAGE_SENT_SUCCESSFULLY &&
                        <SuccessTick />
                    }
                    {modalMessage === SENDING_MESSAGE_TEXT &&
                        <LoaderComponent />
                    }
                </Modal.Body>
                {showModalBtn &&
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    );
};