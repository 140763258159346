import React, { useRef, useEffect, useState, useContext } from 'react';
import { FETCH_BLOG_API } from '../common/Constants';
import './BlogPage.css';
import DOMPurify from 'dompurify';
import { StudioMH02Context } from '../common/StudioMH02Context';
import { fetchBlogs } from '../common/Common';


const BlogPage = () => {
  const [data, setData] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const [description,setDescription] = useState("");
  const { loading, setLoading } = useContext(StudioMH02Context);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogDetails();
  }, []);

  useEffect(()=>{
    setDescription(DOMPurify.sanitize(data?.description));
  },[data]);

  const fetchBlogDetails = async () => {
    try {
      const blogId = queryParams.get('blogId');
      setLoading(true);
      if (blogId) {
        fetchBlogs(null,blogId).then((data) => {
          setData(data?.[0]);
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="BlogPage" style={{ marginTop: '-2.5%' }}>
      {data &&
        <div style={{marginTop:'1.5%'}}>
          <h1 className='project-title'>{data.title}</h1>
          <div className='row blog-page-row card' style={{ borderWidth: 'thin', padding: '3%',paddingRight:'0%', fontSize: 'larger'}} >
            <div className='row' style={{marginTop: '3%'}}>
              <div dangerouslySetInnerHTML={{ __html: description }}>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
export default BlogPage;