import './NavigationBar.css';
import logo from '../static/logo.png';
import { useEffect } from 'react';
import { underlineNavBar } from './common/Common';

export default function NavigationBar() {

    useEffect(() => {
        underlineNavBar();
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-right">
            <img className='logo-image' src={logo} alt="Logo" height='100rem;' />
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse mr-sm-2 menu-items" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item pages">
                        <a className="nav-link" href="/home-page">Home</a>
                    </li>
                    <li className="nav-item pages">
                        <a className="nav-link" href="/the-studio">The Studio</a>
                    </li>
                    <li className="nav-item dropdown pages">
                        {/* <a className="nav-link dropdown-toggle" href="/our-work" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Our Work
                        </a> */}
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Our Work
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="/our-work/?filter=all-projects">All Projects</a>
                            <a className="dropdown-item" href="/our-work/?filter=architecture">Architecture</a>
                            <a className="dropdown-item" href="/our-work/?filter=interior-design">Interior Designs</a>
                            <a className="dropdown-item" href="/our-work/?filter=outsourcing">Outsourcing</a>
                        </div>
                    </li>
                    <li className="nav-item pages">
                        <a className="nav-link" href="/careers">Careers</a>
                    </li>
                    <li className="nav-item pages">
                        <a className="nav-link" href="/blogs">Blogs</a>
                    </li>
                    <li className="nav-item pages">
                        <a className="nav-link" href="/contact-us">Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};