import './App.css';
import react, { useContext, useEffect, useState } from 'react';
import NavigationBar from './components/NavigationBar';
import Careers from './components/webPages/Careers';
import Blogs from './components/webPages/Blogs';
import ContactUs from './components/webPages/ContactUs';
import OurWork from './components/webPages/OurWork';
import TheStudio from './components/webPages/TheStudio';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './components/webPages/Home';
import instagramLogo from './static/instagram.png';
import facebookLogo from './static/facebook.png';
import linkedInLogo from './static/linkedIn.png';
import mapsLogo from './static/maps.png';
import ProjectPage from './components/webPages/ProjectPage';
import UploadProject from './components/admin/UploadProject';
import UploadBlog from './components/admin/UploadBlog';
import BlogPage from './components/webPages/BlogPage';
import { StudioMH02Context } from './components/common/StudioMH02Context';
import { LoaderComponent } from './components/common/Common';
import LoadingScreen from './components/common/LoadingScreen';
import LoginPage from './components/webPages/LoginPage';
import NoAccessPage from './components/webPages/NoAccessPage';
import AdminPage from './components/admin/AdminPage';
import UploadStudioContents from './components/admin/UploadStudioContents';
import ManageClients from './components/admin/ManageClients';
import ManageTeam from './components/admin/ManageTeam';
import ManageJobOptions from './components/admin/ManageJobOptions';
import RedirectPage from './components/webPages/RedirectPage';

function App() {

  const { loading, setLoading, isAdmin } = useContext(StudioMH02Context);
  const [isAdminSessionStorage, setIsAdminSessionStorage] = useState(false);

  useEffect(() => {    
    setIsAdminSessionStorage(sessionStorage.getItem("isAdmin"));
}, []);

  let mybutton = document.getElementById("btn-back-to-top");

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    mybutton = document.getElementById("btn-back-to-top");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div>
      {loading && <LoadingScreen />
      }
      <div>
        <div className={"App"}>
          {isAdminSessionStorage &&
            <div className='nav-div row'>
              <NavigationBar />
            </div>
          }


          <div className='home-body row'>
            <BrowserRouter>
              <Routes>

                <Route path="/" element={<RedirectPage />} />
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/admin" element={isAdmin ? <AdminPage /> : <NoAccessPage />} />
                <Route path="/upload-the-studio-contents" element={isAdmin ? <UploadStudioContents /> : <NoAccessPage />} />
                <Route path="/manage-clients" element={isAdmin ? <ManageClients /> : <NoAccessPage />} />
                <Route path="/manage-team" element={isAdmin ? <ManageTeam /> : <NoAccessPage />} />
                <Route path="/manage-job-options" element={isAdmin ? <ManageJobOptions /> : <NoAccessPage />} />
                <Route path="/upload-project" element={isAdmin ? <UploadProject /> : <NoAccessPage />} />
                <Route path="/upload-blog" element={isAdmin ? <UploadBlog /> : <NoAccessPage />} />
                <Route path="/login" element={<LoginPage />} />

                <Route path="/home-page" element={<Home />} />
                <Route path='/blogs' element={<Blogs />} />
                <Route path='/blog' element={<BlogPage />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/our-work/*" element={<OurWork />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/the-studio" element={<TheStudio />} />
                <Route path="/the-studio" element={<TheStudio />} />
                <Route path="/project" element={<ProjectPage />} />

              </Routes>
            </BrowserRouter>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-floating btn-lg"
          id="btn-back-to-top"
          onClick={() => backToTop()}
        >
          🡱
        </button>
        <footer class="footer" style={{ marginTop: '2%' }}>
          <div class="bg-light border-top border-light-subtle ipad-footer">
            <div class="container overflow-hidden">
              <div class="row align-items-sm-center">
                <div class="footer-ipad">
                  <div class="footer-logo-wrapper text-center text-sm-start">
                    <a href="/home-page" style={{ textDecoration: 'none' }}>
                      <span className='heading-home-footer'>Studio MH02 LLP</span>
                    </a>
                    <br />
                    <br />
                    <p>
                      1st Floor, Bhide Bunglow, MG road <br /> Vile Parle, E, Maharashtra 400057 <br /> Phone: <a href="tel:+91 9769911588">+91 9769911588</a> <br /> E-mail : <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@studiomh02.in">info@studiomh02.in</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ margin: '0px', width: '35%', margin: 'auto' }} />
          <div class="bg-light py-2 py-md-3">
            <div class="container overflow-hidden">
              <div class="row">
                  <div className='rights-footer'> &copy; Studio MH02 + Bimesign  | 2021 All Rights Reserved.</div>
                  <div class="social-media-wrapper" style={{ float: 'inline-end' }}>
                    <ul class="list-unstyled m-0 p-0 d-flex">
                      <li style={{ marginRight: '4%' }}>
                        <a href="https://www.instagram.com/studiomh02/">
                          <img src={instagramLogo} width="32" height="32" viewBox="0 0 16 16" />
                        </a>
                      </li>
                      <li style={{ marginRight: '4%' }}>
                        <a href="https://www.facebook.com/people/Studio-MH02/100070909748562/?locale=cy_GB">
                          <img src={facebookLogo} width="32" height="32" viewBox="0 0 16 16" />
                        </a>
                      </li>
                      <li style={{ marginRight: '4%' }}>
                        <a href="https://www.linkedin.com/company/studio-mh02/?originalSubdomain=in">
                          <img src={linkedInLogo} width="32" height="32" viewBox="0 0 16 16" />
                        </a>
                      </li>
                      <li style={{ marginRight: '4%' }}>
                        <a href="https://maps.app.goo.gl/BBVoSpy2Y9U9Nig38">
                          <img src={mapsLogo} width="32" height="32" viewBox="0 0 16 16" />
                        </a>
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
