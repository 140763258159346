import { useContext, useEffect, useState } from "react";
import { StudioMH02Context } from "../common/StudioMH02Context";
import { BASE_API, FETCH_JOB_OPTION, FETCH_JOB_OPTIONS_API } from "../common/Constants";
import { CareersCard, JobOptionAccordion, underlineNavBar } from "../common/Common";
import './Careers.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Careers() {
    const { loading, setLoading } = useContext(StudioMH02Context);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const applyNowBtn = () => {
        navigate("/contact-us");
        underlineNavBar();
    }

    const fetchData = async () => {
        setLoading(true);
        await fetchJobOptions();
        setLoading(false);
    };

    const fetchJobOptions = async () => {
        axios.post(BASE_API + FETCH_JOB_OPTIONS_API)
            .then((response) => {                                
                setData(response?.data);
            })
    }

    return (
        <div className="careers-div">
            <span className='heading-home'>Build Your Career With Us</span>
            <p className='small-heading'> Explore Career Opportunities with current job openings..</p>

            <div className='row' style={{ margin: 'auto', width: '80%' }}>
                {/* {
                    data?.map((data, index) => <CareersCard data={data} index={index} totalOptions={data?.length} />)
                } */}

                <div class="accordion accordion-flush the-studio-accordian-card card" id="accordionFlushExample">
                    {
                        data?.map((row, index) => <JobOptionAccordion row={row} index={index} />)
                    }
                </div>
            </div>
            <p className='small-heading-green'> Can't find a job description that matches your skills? No worries! We're always on the lookout for talented individuals. Just fill out the form with your details, and we'll be in touch.</p>
            <div className='row' style={{ marginTop: '4%' }}>
                <button className="learn-more-btn" onClick={() => { applyNowBtn() }}>Apply Now </button>
            </div>
        </div>
    );
};