import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { StudioMH02Context } from '../common/StudioMH02Context';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { BASE_API, LOGIN_API } from '../common/Constants';

const LoginPage = () => {
    const navigate = useNavigate();
    const { loading, setLoading, isAdimn, setIsAdmin } = useContext(StudioMH02Context);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
            try {
                const response = await fetch(BASE_API+LOGIN_API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username, password }),
                });
        
                const data = await response.json();
        
                if (data.status === 'success') {
                    setIsAdmin(true);
                    sessionStorage.setItem("isAdmin", true);
                    navigate("/admin");
                } else {
                    setMessage(data.message);
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        
    };

    return (
        <div className='login-register-form'>
            <form onSubmit={handleLogin} className='contact-us-form'>
                <div className='login-row'>
                    <label>Username:</label>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className='login-row'>
                    <label>Password:</label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <p>{message}</p>

                <button className="learn-more-btn" type="submit" style={{ marginTop: '5% !important' }}>Login</button>
            </form>
        </div>
    );
};

export default LoginPage;
