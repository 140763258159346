// src/context/StudioMH02Context.js
import React, { createContext, useState } from 'react';

// Create a context
export const StudioMH02Context = createContext();

// Create a provider component
export const StudioMH02Provider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <StudioMH02Context.Provider value={{ loading, setLoading ,isAdmin, setIsAdmin}}>
      {children}
    </StudioMH02Context.Provider>
  );
};