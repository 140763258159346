import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css';
import axios from 'axios';
import { BASE_API, BASE_API_STATIC_COMMON_FOLDER, GET_IMAGES } from '../common/Constants';

const Carousel = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
        await fetchHomeSliderImages();
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchHomeSliderImages = async () => {
    try {
      const associatedType = "home-slider-images";
      const response = await axios.post(BASE_API+GET_IMAGES+`${associatedType}`);
      let imageNames = response.data;   
      setImages(imageNames);
    } catch (e) {
    }
  };


  useEffect(() => {
    if (images.length > 0) startAutoSlide();
    return () => stopAutoSlide(); 
  }, [currentIndex, images.length]);

  const startAutoSlide = () => {
    stopAutoSlide(); 
    if (images.length > 0) {
      timerRef.current = setInterval(goToNextSlide, 5000); 
    }
  };

  const stopAutoSlide = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };


  const goToNextSlide = () => {
    if (images.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const goToPrevSlide = () => {
    if (images.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
  };

  return (
    <div className="carousel-container-home" onMouseEnter={stopAutoSlide} onMouseLeave={startAutoSlide}>
      {images.length > 0 && (
        <div className="carousel-slide" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((image, index) => (
            <img class="d-block w-100  carousel-img-home" key={index} src={BASE_API_STATIC_COMMON_FOLDER+'/home/' + image} alt={`Slide ${index + 1}`} className="carousel-image" />
          ))}
        </div>
      )}
      <button className="carousel-arrow left-arrow" onClick={goToPrevSlide}>❮</button>
      <button className="carousel-arrow right-arrow" onClick={goToNextSlide}>❯</button>
    </div>
  );
};

export default Carousel;
