import react, { useContext, useEffect, useState } from 'react';
import { CardRenderer, fetchProjects } from '../common/Common';
import { FETCH_PROJECT_API } from '../common/Constants';
import { StudioMH02Context } from '../common/StudioMH02Context';

export default function OurWork() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState("");
    const { loading, setLoading } = useContext(StudioMH02Context);
    const filtersMap = new Map([
        ["architecture", "Architecture"],
        ["interior-design", "Interior Design"],
        ["outsourcing", "Outsourcing"]
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        setFilter(params.get('filter'));
        fetchProjectsData();
    }, []);

    useEffect(() => {
        if (filter !== "" && filter !== "all-projects") {
            setFilteredData(data.filter(a => a.tags.includes(filtersMap.get(filter))));
        }
        else {
            setFilteredData(data);
        }
    }, [data, filter]);

    const fetchProjectsData = async () => {
        try {
            setLoading(true);
            fetchProjects(null,-1).then((data) => {
                setData(data);
              });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    return (
        <div className='row' style={{ margin: 'auto', width: '80%' }}>
            {
                filteredData.map((project, index) => <CardRenderer data={project} index={index} totalProjects={filteredData.length} />)
            }
        </div>
    );
};