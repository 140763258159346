import { useContext, useEffect, useState } from 'react';
import './AdminPage.css';
import { StudioMH02Context } from '../common/StudioMH02Context';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { BASE_API, HOME_SLIDER_IMAGES_UPLOADED_SUCCESSFULLY, UPLOAD_IMAGE, UPLOAD_IMAGES } from '../common/Constants';
import { useNavigate } from 'react-router-dom';
const AdminPage = () => {

    const { loading, setLoading } = useContext(StudioMH02Context);
    const [homeSliderImages, setHomeSliderImages] = useState([]);
    const [showUploadHomeImagesSliderModal, setShowUploadHomeImagesSliderModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleCloseHomeImagesSliderModal = () => {
        setShowUploadHomeImagesSliderModal(false);
    }

    const uploadHomeSliderImages = async (e) => {
        e.preventDefault()
        
        const payload = new FormData();
        for (let i = 0; i < homeSliderImages.length; i++) {
            payload.append("files[]", homeSliderImages[i])
        };
        payload.append("associatedType", "home-slider-images");
        axios.post(BASE_API+UPLOAD_IMAGE, payload)
            .then((response) => {
                setShowUploadHomeImagesSliderModal(false);
                setShowModal(true);
                setModalMessage(HOME_SLIDER_IMAGES_UPLOADED_SUCCESSFULLY);
                setTimeout(() => {
                    setShowModal(false);
                    setModalMessage("");
                }, 3000)                
            })
    };

    const openUploadHomeSliderImagesPopUp = () => {
        setShowUploadHomeImagesSliderModal(true);
    };

    const openTheStudioContent = () => {
        navigate("/upload-the-studio-contents");
    };

    const openUploadProjects = () => {
        navigate("/upload-project");
    };
    const openUploadBlogs = () => {
        navigate("/upload-blog");
    };

    const openManageClients = () => {
        navigate("/manage-clients");
    };

    const openManageTeam = () => {
        navigate("/manage-team");
    };

    const openManageCareers = () => {
        navigate("/manage-job-options");
    };

    const handleHomeSliderImages = (e) => {
        const files = Array.from(e.target.files);
        setHomeSliderImages(files);
    }

    return (
        <div>
            <span className='heading-home'>Admin Page</span>
            <hr className="hr-home" />
            <div className='admin-container'>
                <div className='row'>
                    <button class="button-rounded" onClick={openUploadHomeSliderImagesPopUp}>Upload Home Slider Images </button>
                </div>
                <div className='row'>
                    <button class="button-rounded" onClick={openTheStudioContent}>Upload The Studio Contents </button>
                </div>
                <div className='row'>
                    <button class="button-rounded" onClick={openUploadProjects}>Upload Projects </button>
                </div>
                <div className='row'>
                    <button class="button-rounded" onClick={openUploadBlogs}>Upload Blogs </button>
                </div>
                <div className='row'>
                    <button class="button-rounded" onClick={openManageClients}>Manage Clients</button>
                </div>
                <div className='row'>
                    <button class="button-rounded" onClick={openManageTeam}>Manage Team</button>
                </div>
                <div className='row'>
                    <button class="button-rounded" onClick={openManageCareers}>Manage Job Options</button>
                </div>
            </div>

            <Modal show={showUploadHomeImagesSliderModal} onHide={handleCloseHomeImagesSliderModal} className='studio-mh02-modal'>
                <Modal.Body>
                    <form onSubmit={uploadHomeSliderImages} className='contact-us-form'>
                        <div class="form-group row upload-project-row">
                            <div class="col-sm-10" style={{ display: 'contents' }}>
                                <input onChange={handleHomeSliderImages} type="file" class="form-control-file" id="exampleFormControlFile1" multiple />
                            </div>
                        </div>
                        <div class="form-group row upload-project-row">
                            <div class="col-sm-10">
                                <button>Upload Photos</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} className='studio-mh02-modal'>
                <Modal.Body>
                    {modalMessage}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AdminPage;